<template>
    <div>
        <v-dialog 
            v-if="dataSource" 
            v-model="isVisible" 
            transition="dialog-top-transition" 
            persistent 
            max-width="100%"            
        >
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card flat>
                        <v-card-text>
                            <v-form ref="form" lazy-validation> 
                                <v-tabs
                                    v-model="sectionModel"
                                    background-color="transparent"
                                    mobile-breakpoint="0"
                                    class="vertical-tab-panel"
                                    vertical
                                >
                                    <v-tabs-items
                                        v-model="sectionModel"
                                        class="tab-content-panel"
                                    >
                                        <v-tab-item 
                                            v-for="(section, sectionKey) in sections"
                                            :key="sectionKey"
                                        >
                                            <v-simple-table class="customization-list-table bordered-table no-padding-td-table white-thead-table table-with-sticky-header">
                                                <template v-slot:default>
                                                    <thead>                                                            
                                                        <tr 
                                                            v-for="(trdata, trKey) in getHeadTRData(section)" 
                                                            :key="'hs'+sectionKey+'tr'+trKey"
                                                        >
                                                            <th 
                                                                v-for="(thdata, thKey) in trdata" 
                                                                :key="'hs'+sectionKey+'tr'+trKey+'th'+thKey"
                                                                :rowspan="thdata.rowspan"
                                                                :colspan="thdata.colspan"
                                                            >{{ localized(thdata.title) }}<i class="fas fa-info"></i></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody :key="tbodykey">
                                                        <tr 
                                                            v-for="(trdata, trKey) in getBodyTRData(section)" 
                                                            :key="'bs'+sectionKey+'tr'+trKey"
                                                        >
                                                            <td
                                                                v-for="(tddata, tdKey) in trdata.data"
                                                                :key="'ds'+sectionKey+'tr'+trKey+'td'+tdKey"
                                                            >
                                                                    <template v-if="!!getDataType(section, tdKey) && !trdata.readonly">
                                                                    <v-text-field
                                                                        hide-details                                                                        
                                                                        dense
                                                                        type="number"
                                                                        class="cust-inputnumber"
                                                                        min="0"
                                                                        :value="tddata"
                                                                        @input="e => {updateDataSource({ property: `sections[${sectionKey}].rows[${trKey}].data[${tdKey}]`, value: e }); tbodykey=$moment().valueOf();}" 
                                                                    >
                                                                    </v-text-field>
                                                                </template> 
                                                                <template v-else>
                                                                    {{  typeof tddata === 'string' && tddata.startsWith('this.') ? calculate(tddata, sectionKey, trKey, tdKey) : localized(tddata) }}    
                                                                </template>
                                                            </td>
                                                        </tr>                                                         
                                                    </tbody>
                                                </template>  
                                            </v-simple-table>
                                        </v-tab-item>
                                    </v-tabs-items>
                                                                    
                                    <v-tabs-slider color="#E43958"></v-tabs-slider>

                                    <v-tab 
                                        v-for="(section, sectionKey) in sections"
                                        :key="sectionKey"
                                    >
                                        <span v-tooltip.top-center="section.title">{{ localized(section.title) }}</span>
                                    </v-tab>

                                </v-tabs>
                                <!--
                                <v-expansion-panels v-model="sectionModel">
                                    <v-expansion-panel
                                        v-for="(section, sectionKey) in sections"
                                        :key="sectionKey"                                            
                                    >
                                        <v-expansion-panel-header>{{ localized(section.title) }}</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-simple-table class="customization-list-table customization-history-list-table bordered-table no-padding-td-table">
                                                <template v-slot:default>
                                                    <thead>                                                            
                                                        <tr 
                                                            v-for="(trdata, trKey) in getHeadTRData(section)" 
                                                            :key="'hs'+sectionKey+'tr'+trKey"
                                                        >
                                                            <th 
                                                                v-for="(thdata, thKey) in trdata" 
                                                                :key="'hs'+sectionKey+'tr'+trKey+'th'+thKey"
                                                                :rowspan="thdata.rowspan"
                                                                :colspan="thdata.colspan"
                                                            >{{ localized(thdata.title) }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr 
                                                            v-for="(trdata, trKey) in getBodyTRData(section)" 
                                                            :key="'bs'+sectionKey+'tr'+trKey"
                                                        >
                                                            <td
                                                                v-for="(tddata, tdKey) in trdata.data"
                                                                :key="'ds'+sectionKey+'tr'+trKey+'td'+tdKey"
                                                            >
                                                                 <template v-if="!!getDataType(section, tdKey) && !trdata.readonly">
                                                                    <v-text-field
                                                                        hide-details
                                                                        
                                                                        dense
                                                                        type="number"
                                                                        class="cust-inputnumber"
                                                                        min="0"
                                                                        :value="tddata"
                                                                        @input="e => updateDataSource({ property: `sections[${sectionKey}].rows[${trKey}].data[${tdKey}]`, value: e })" 
                                                                    >
                                                                    </v-text-field>
                                                                </template> 
                                                                <template v-else>
                                                                    {{ localized(tddata) }}    
                                                                </template>                                                                
                                                            </td>
                                                        </tr>                                                         
                                                    </tbody>
                                                </template>  
                                            </v-simple-table> 
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>  
                                -->                              
                            </v-form>
                        </v-card-text>
                    </v-card>  
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        color="cyan"
                        text
                        depressed
                        @click="ok"
                        v-if="isValid"
                    >
                        {{ $t("Ок") }} 
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отменить")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import sys from '@/services/system';

export default {
    name: "tableGenerator",
    oldVal: null,
    data () {
        return {
            sectionModel: null,
            tbodykey: 0
        }
    },
    filters: {
        formattedDate: function (value) {
            return sys.dateFormat(value, 'DD.MM.YYYY') ?? "";
        }
    },
    computed: {
        ...mapGetters('dialogs/tableGenerator', ['isPending', 'isVisible', 'isValid', 'dataSource', 'skey']),
        title() {            
            return this.localized(this.dataSource.title);
        },
        sections() {
            return this.dataSource.sections;
        }
    },    
    methods: {
        ...mapActions('dialogs/tableGenerator', ['ok', 'cancel', 'updateDataSource']),   
        validateForm() {
            this.$refs?.form?.validate();    
        },
        localized(val) {
            if (val == null)
                return val;
            if (typeof val === 'string') 
                return val;            
            else if (val[this.$i18n.locale] !== undefined)            
                return val[this.$i18n.locale];

            return null;
        },
        eval(strCode) {
            return eval(strCode);
        },
        calculate(tddata, sectionKey, trKey, tdKey) {
            console.log(tddata, sectionKey, trKey, tdKey);
            switch(tddata)
            {
                case "this.columnSumm":
                    {
                        return this.dataSource.sections[sectionKey].rows.reduce((a, v) => {

                            if (v.readonly)
                                return a;

                            let value = parseInt(v.data[tdKey]);

                            if (!isNaN(value))                            
                                a += value;                            

                            return a;
                        }, 0);
                    } 

                // case "this.rowSumm": 
                // {
                //     return this.dataSource.sections[sectionKey].rows[trKey].data.reduce((a, v, i) => {

                //         //TODO use cache of getDataType
                //         if (this.getDataType(this.dataSource.sections[sectionKey], i))
                //         {
                //             let value = parseInt(v);

                //                 if (!isNaN(value))                            
                //                     a += value;  
                //         }

                //         return a;
                //     }, 0);
                // }
            }

            return null;
        },
        getHeadTRData(section) {
            let data = [];
            let hasNextLevel = true;
            let items = section.cols;
            let level = 0;            
            let deepLevel = this.getDeepLevel(items);

            while(hasNextLevel) {
                level++;

                let result = items.reduce((acc, item) => {
                    acc.push({title: item.title, rowspan: (!Array.isArray(item.items) || item.items?.length == 0) && deepLevel - level + 1 > 1 ? deepLevel - level + 1 : null , colspan: this.getColspan(item)});
                    return acc;
                }, []);

                data.push(result);

                items = items.reduce((acc, item) => {
                    if (Array.isArray(item.items) && item.items?.length > 0)
                        item.items.forEach(i => acc.push(i));

                    return acc;
                }, []);

                hasNextLevel = items.length > 0;
            }

            return data;
        },
        getColspan(v){
            let result =  v.items?.length > 0 
            ? this.getWidthLevel(v.items)
            : null;            

            return result;
        },
        getDeepLevel(data) {
            let result = 0;
            let hasNextLevel = true;
            let items = data

            while(hasNextLevel) {
                result++;
                items = items.reduce((acc, item) => {
                    if (item.items?.length > 0)
                        item.items.forEach(i => acc.push(i));
                    return acc;
                }, []);

                hasNextLevel = items.length > 0;
            } 
            
            return result;
        },
        getWidthLevel(data) {
            let result = data.filter(i => !Array.isArray(i.items) || i.items.length == 0).length;
            let hasNextLevel = true;
            let items = data

            while(hasNextLevel) {
                items = items.reduce((acc, item) => {
                        item.items?.forEach(i => acc.push(i));
                    return acc;
                }, []);

                hasNextLevel = items.length > 0;
                
                result += items.filter(i => !Array.isArray(i.items) || i.items.length == 0).length
            } 
            
            return result;
        },
        getBodyTRData(section){            
           return section.rows;
        },
        getDataType(section, key) {
            let items = section.cols;
            let result = items.filter(i => !Array.isArray(i.items) || i.items.length == 0);
            let hasNextLevel = true;

            while(hasNextLevel) {
                items = items.reduce((acc, item) => {
                        item.items?.forEach(i => acc.push(i));
                    return acc;
                }, []);

                hasNextLevel = items.length > 0;
                
                items.filter(i => !Array.isArray(i.items) || i.items.length == 0).forEach(i => result.push(i));
            } 
            
            return result[key]?.type;
        }
    },
    mounted() {
        // let whenKeys = Object.keys(this.dataSource.fields).filter(key => Object.prototype.hasOwnProperty.call(this.dataSource.fields[key], 'when'));
        // whenKeys.forEach(key => {                    
        //     let value = this.dataSource.fields[key].value === null ? 'null' : this.dataSource.fields[key].value;  
        //     if (Object.prototype.hasOwnProperty.call(this.dataSource.fields[key].when, value))    
        //     {
        //         let fieldsKeys = Object.keys(this.dataSource.fields[key].when[value]);
        //         fieldsKeys.forEach(k => {
        //             let changingFields = Object.keys(this.dataSource.fields[key].when[value][k]);
        //             changingFields.forEach(c => {
        //                 let paramValue = this.dataSource.fields[key].when[value][k][c];
        //                 this.$nextTick(function() {
        //                     this.updateDataSource({ property: `fields.${k}.${c}`, value: paramValue });
        //                 });
        //             });
        //         });
        //     }
        // });
    },
    updated() {
        this.$nextTick(function () {
            // if (this.isVisible)
            //     this.validateForm();
            //console.log('updated');
        });    
    },

    watch:{
        dataSource: {
            handler(oldVal, newVal) {
                console.log(oldVal, newVal);
                // let whenKeys = Object.keys(this.dataSource.fields).filter(key => Object.prototype.hasOwnProperty.call(this.dataSource.fields[key], 'when'));
                // whenKeys.forEach(key => {                    
                //     let value = this.dataSource.fields[key].value === null ? 'null' : this.dataSource.fields[key].value;  
                //     if (Object.prototype.hasOwnProperty.call(this.dataSource.fields[key].when, value))                          
                //     if (!this.$options.oldVal || this.$options.oldVal.fields[key].value != value)
                //     {
                //         let fieldsKeys = Object.keys(this.dataSource.fields[key].when[value]);
                //         fieldsKeys.forEach(k => {
                //             let changingFields = Object.keys(this.dataSource.fields[key].when[value][k]);
                //             changingFields.forEach(c => {
                //                 let paramValue = this.dataSource.fields[key].when[value][k][c];
                //                 this.$nextTick(function() {
                //                     this.updateDataSource({ property: `fields.${k}.${c}`, value: paramValue });
                //                 });
                //             });
                //         });
                //     }
                // });
                
                // this.$options.oldVal = JSON.parse(JSON.stringify(newVal));
            },
            deep: true
        }
    }
}
</script>